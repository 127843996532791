import tokenService from "./tokenService"
const api =
  process.env.VUE_APP_DD_ENV === "local"
    ? process.env.VUE_APP_PROXY_API
    : process.env.VUE_APP_API

const baseUrl =
  process.env.VUE_APP_DD_ENV === "local"
    ? process.env.VUE_APP_PROXY_BASE_URL
    : process.env.VUE_APP_BASE_URL

const isProd = process.env.VUE_APP_DD_ENV === "production" // check if the app is using production .env
const isProductionBuild = process.env.NODE_ENV === "production" // check if the app is build for real environment (dev, stage, prod) not local

const appConfig = {
  baseUrl,
  api,
  isProd,
  isProductionBuild,
  getAuthToken: function () {
    return !this.isProductionBuild ? tokenService.getDevToken() : null // we must authorize all real envrionments (dev, stage, prod) with cookie and only local with token
  },
  axios: function () {
    return {
      baseUrl,
      withCredentials: this.isProductionBuild,
      Authorization: this.getAuthToken()
        ? `Bearer ${this.getAuthToken()}`
        : null
    }
  }
}

export default appConfig
