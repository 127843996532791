const MODULE = "Visitor Management"
const VISITOR_MODULE_ID = 6

const ALLOWED_VISITOR_SCHOOL_IDS =
  process?.env?.VUE_APP_ALLOWED_VISITOR_SCHOOL_IDS?.split(",")
    ?.filter(Boolean)
    ?.map(Number) || []

const ALERTS = Object.freeze({
  EMERGENCY: "EMERGENCY_ALERT",
  SEX_OFFENDER: "SEX_OFFENDER_ALERT",
  WATCHLIST: "WATCHLIST_ALERT"
})

const GENERAL_SETTINGS = Object.freeze({
  AUTOMATIC_CHECKOUT: "AUTOMATIC_CHECKOUT", // GENERAL SETTINGS
  SCHOOL_START_TIME: "SCHOOL_START_TIME", // GENERAL SETTINGS
  SCHOOL_END_TIME: "SCHOOL_END_TIME", // GENERAL SETTINGS
  SCHOOL_TIMEZONE: "SCHOOL_TIMEZONE" // GENERAL SETTINGS
})

const SETTINGS = Object.freeze({
  EMERGENCY: "EMERGENCY_ALERTS",
  SEX_OFFENDER: "SEX_OFFENDERS",
  WATCHLIST: "WATCHLIST_ALERTS",
  PRE_APPROVED_VISITOR: "PREAPPROVED_VISITOR",
  SIS: "SIS",
  DESTINATION: "DESTINATION",
  PHOTO_CAPTURE: "TAKE_PICTURE",
  FACIAL_RECOGNITION: "FACIAL_REKOGNITION",
  ID_SCANNING: "ID_SCANNING",
  PHONE_NUMBER_ENTRY: "PHONE_NUMBER_ENTRY",
  SELF_CHECKOUT: "SELF_CHECKOUT",
  MULTILINGUAL_SUPPORT: "MULTILINGUAL_SUPPORT",
  SELF_BADGE_PRINT: "SELF_BADGE_PRINT",
  HEALTH_SCREENING: "HEALTH_SCREENING",
  CUSTOM_LOGO: "CUSTOMIZE_LOGO",
  AUTOMATIC_CHECKOUT: GENERAL_SETTINGS.AUTOMATIC_CHECKOUT,
  SCHOOL_START_TIME: GENERAL_SETTINGS.SCHOOL_START_TIME,
  SCHOOL_END_TIME: GENERAL_SETTINGS.SCHOOL_END_TIME,
  SCHOOL_TIMEZONE: GENERAL_SETTINGS.SCHOOL_TIMEZONE,
  FORM_PICK_UP: "PICK_UP",
  PICK_1: "TARDY_PICK_CUSTOM1",
  PICK_2: "TARDY_PICK_CUSTOM2",
  PICK_3: "TARDY_PICK_CUSTOM3",
  PICK_OTHER: "TARDY_PICK_OTHER",
  FORM_DROP_OFF: "DROP_OFF",
  DROP_1: "TARDY_DROP_CUSTOM1",
  DROP_2: "TARDY_DROP_CUSTOM2",
  DROP_3: "TARDY_DROP_CUSTOM3",
  DROP_OTHER: "TARDY_DROP_OTHER",
  CUSTOM_1: "VISIT_WITH_TEACHER",
  CUSTOM_2: "VISIT_WITH_PRINCIPAL",
  CUSTOM_3: "REGISTER_FOR_VOLUNTEERING",
  CUSTOM_4: "CUSTOM_REASON_1",
  CUSTOM_5: "CUSTOM_REASON_2",
  CUSTOM_OTHER: "OTHERS",
  HEALTH_QSTN_1: "QUESTION1",
  HEALTH_QSTN_2: "QUESTION2",
  HEALTH_QSTN_3: "QUESTION3",
  HEALTH_QSTN_4: "QUESTION4",
  HEALTH_QSTN_5: "QUESTION5",
  HEALTH_QSTN_6: "QUESTION6"
})

const VISITOR_CAMERA_TYPES = Object.freeze({
  COMPUTER: "computer",
  IOS: "ios",
  ANDROID: "android"
})

const VISITOR_CAMERA_REQUEST_CATEGORY = Object.freeze({
  [VISITOR_CAMERA_TYPES.IOS]: "web2ipad_vp",
  [VISITOR_CAMERA_TYPES.ANDROID]: "web2android_vp"
})

const VISITOR_LOCAL_STORAGE_KEYS = Object.freeze({
  PRINTER_ID: "visitor_printer_id",
  PRINTER_NAME: "visitor_printer_name",
  PRINTER_BRAND: "visitor_printer_brand",
  PRINTER_FINGERPRINT: "visitor_fingerprint",
  USER: "visitor_user",
  STOP_AUTO_DETECT: "visitor_printer_stop_auto_detect",
  SHOW_VISITOR_SIGNED_IN_LIST_MODAL:
    "visitor_show_visitor_signed_in_list_modal",
  DEFAULT_CAMERA: "visitor_default_camera",
  FCM_TOKEN: "visitor_fcm_token"
})

const VISITOR_DEFAULT_GENERAL_SETTINGS = Object.freeze({
  START_TIME: "00:00",
  END_TIME: "13:00",
  TIME_ZONE:
    Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || "America/New_York",
  AUTOMATIC_CHECKOUT: false
})

const VALIDATIONS = Object.freeze({
  REQUIRED: "This field is required",
  ALPHA: "This field must contain only alphabets",
  INVALID_EMAIL: "This field must be a valid email address",
  MAX_160: "This field cannot exceed 160 characters",
  MAX_30: "This field cannot exceed 30 characters",
  MAX_10: "This field cannot exceed 10 characters",
  MAX_100: "This field cannot exceed 100 characters",
  INVALID_PHONE: "This field is invalid, please use (111) 222-3333 format",
  ALPHA_NUM_SPACE_SPECIAL_CHARACTER:
    "This field must contain only alphabets, spaces, numbers, and special characters like - , . , & , : , _ , @"
})

const PRINTER_SETUP = Object.freeze({
  PRINTER_PREVIEW_MODAL_TITLE: "Visitor Brother Printer Preview"
})

const PRINTER_CONFIG = Object.freeze({
  BROTHER: {
    IMAGE: "/img/brand/brother.png",
    NAME: "Brother",
    BRAND: "brother"
  },
  DYMO: {
    IMAGE: "/img/brand/dymo.png",
    NAME: "Dymo",
    BRAND: "dymo"
  }
})

const PRINTER_MESSAGES = Object.freeze({
  NO_DYMO_PRINTERS_FOUND: "No DYMO printers are found.",
  DYMO_PRINTER_NOT_CONNECTED: "DYMO printer is not connected.",
  NO_PRINTER_CONFIGURED: "No printer is configured.",
  ERROR_IN_DETECT_DYMO_PRINTER: "Error in detecting and setting DYMO printer.",
  BROTHER_PRINTER_NOT_ALLOWED:
    "Brother printer is configured in Printer Setup. Please add DYMO printer for this type of printing."
})

const KIOSK_SETTINGS = Object.freeze({
  HEALTH_SCREENING_SETTING_TITLE:
    "Customized Questions (Visitor Health Screening Questions)",
  CUSTOMIZE_HEALTH_SCREENING_MODAL_TITLE:
    "Customized Questions (Edit or Add a Visitor Health Screening Question)",
  CUSTOMIZE_EDIT_DESTINATIONS_MODAL_TITLE:
    "Customized Destinations (Add or Edit Destinations)",
  CUSTOMIZE_EDIT_REASONS_MODAL_TITLE:
    "Customized Reasons (Select a Reason to View and Edit)",
  CUSTOMIZE_HEALTH_SCREENING_ENGLISH_INPUT_PLACEHOLDER: "Customized Question",
  CUSTOMIZE_HEALTH_SCREENING_SPANISH_INPUT_PLACEHOLDER:
    "Pregunta de detección de salud"
})

const LIST_SETTINGS = Object.freeze({
  WATCH_LIST_MODAL_TITLE: "Add to Watch List",
  EMERGENCY_CONTACT_MODAL_TITLE: "List 2 (Emergency Contacts)",
  SECURITY_STAFF_MODAL_TITLE: "List 1 (Security Staff)",
  PRE_APPROVED_VISITOR_MODAL_TITLE:
    "Add Pre-set Visitor List (Add Pre-approved Visitor List)",
  WATCH_LIST_SECTION_HEADER: "Watch List",
  EMERGENCY_CONTACT_SECTION_HEADER: "List 2 (Emergency Contacts)",
  SECURITY_STAFF_SECTION_HEADER: "List 1 (Security Staff)",
  PRE_APPROVED_VISITOR_SECTION_HEADER:
    "Pre-set Visitor List (Visitor Pre-approved List)",
  WATCH_LIST_LEARN_MORE: "visitor_list_settings_watchlist",
  EMERGENCY_CONTACT_LEARN_MORE: "visitor_list_settings_emergency_contacts",
  SECURITY_STAFF_LEARN_MORE: "visitor_list_settings_security_staff",
  PRE_APPROVED_VISITOR_LEARN_MORE: "visitor_list_settings_pre_approved_visitor",
  WATCH_LIST_ADD_BUTTON: "Watch List (Add to watch list)",
  EMERGENCY_CONTACT_ADD_BUTTON: "Emergency contact (Add emergency contact)",
  SECURITY_STAFF_ADD_BUTTON: "Security staff (Add security staff)",
  PRE_APPROVED_VISITOR_ADD_BUTTON: "Pre-set visitor (Add pre-approved visitor)"
})

const SECURITY_SETTINGS = Object.freeze({
  EMERGENCY: {
    HEADING: "Emergency Alerts",
    DESCRIPTION:
      "Send alerts to security staff and other emergency contacts in the event of an emergency.",
    SUBJECT_INPUT_LABEL: "Enter subject line for emergency email",
    CONTENT_INPUT_LABEL: "Enter content for emergency alert text and email",
    CONTENT_KEY: "visitor_security_settings_emergency_alerts"
  },
  SEX_OFFENDER: {
    HEADING: "Sex Offender Alerts",
    DESCRIPTION:
      "Send alerts to specified staff when a sex offender is present on campus.",
    SUBJECT_INPUT_LABEL: "Enter subject line for sex offender email",
    CONTENT_INPUT_LABEL: "Enter content for sex offender alert text and email",
    CONTENT_KEY: "visitor_security_settings_sex_offender_alerts"
  },
  WATCHLIST: {
    HEADING: "Watch List Alerts",
    DESCRIPTION:
      "Send alerts to specified staff when a watch list person is present on campus.",
    SUBJECT_INPUT_LABEL: "Enter subject line for watch list email",
    CONTENT_INPUT_LABEL: "Enter content for watch list alert text and email",
    CONTENT_KEY: "visitor_security_settings_watchlist_alerts"
  }
})

const CHECK_IN_FORM = Object.freeze({
  REASON_DEFAULTS: "REASON_DEFAULTS",
  MODAL_TITLE_TAKE_PHOTO: "Take photo from computer",
  MODAL_TITLE_HEALTH_CHECK_FAILED: "Visitor health check failed",
  MODAL_TITLE_NON_PRE_APPROVED_VISITOR: "Non pre-set visitor",
  MODAL_TITLE_OFFENDER_MATCH: "Possible offender alert",
  MODAL_TITLE_WATCHLIST_MATCH: "Possible watch list alert",
  MODAL_TITLE_WATCHLIST_OR_OFFENDER_MATCH:
    "Possible watch list or offender alert",
  MODAL_DESCRIPTION_WATCHLIST_OR_OFFENDER_MATCH:
    "Please select 'Match' or 'Not a match' based on the information below - use blue caret to move through all pages.",
  MODAL_DESCRIPTION_WATCHLIST_MATCH:
    "Please select 'Match' or 'Not a match' based on the information below.",
  MODAL_DESCRIPTION_OFFENDER_MATCH:
    "Please select 'Match' or 'Not a match' based on the information below.",
  MODAL_TITLE_OFFENDER: "Offender match",
  MODAL_TITLE_WATCHLIST: "Watch list match",
  MODAL_TITLE_NON_GUARDIAN_VISITOR: "Non guardian visitor alert",
  MODAL_TITLE_HEALTH_CHECK_FAILED_EXCEPTION: "Health screening exception",
  MODAL_TITLE_NON_PRE_APPROVED_VISITOR_EXCEPTION:
    "Non pre-set visitor exception",
  MODAL_TITLE_OFFENDER_EXCEPTION: "Offender exception",
  MODAL_TITLE_EXCEPTION_EXCEPTION: "Watch list exception",
  MODAL_TITLE_NON_GUARDIAN_VISITOR_EXCEPTION: "Non guardian visitor exception",
  VISIT_STATUS_DENIED: "denied",
  VISIT_STATUS_SIGNED_IN: "signed in",
  VISIT_STATUS_SIGNED_OUT: "signed out",
  MATCH_TYPE_PRE_APPROVED_VISITOR: "pre-approved-visitor",
  MATCH_TYPE_NON_GUARDIAN_VISITOR: "non-guardian",
  MATCH_TYPE_HEALTH_CHECK_FAILED: "health screening",
  MATCH_TYPE_VISITOR: "visitor",
  MATCH_TYPE_OFFENDER: "offender",
  MATCH_TYPE_WATCHLIST: "watchlist",
  EXCEPTION_MESSAGE_HEALTH_CHECK_FAILED: "Visitor health check failed",
  EXCEPTION_MESSAGE_NON_PRE_APPROVED_VISITOR:
    "The guest is not present in the pre-set visitor list",
  MODAL_TITLE_VISITORS_STILL_SIGNED_IN: "Signed in visitors alert",
  MODAL_TITLE_DOWNLOAD_REPORT: "Download report",
  MODAL_TITLE_EMAIL_REPORT: "Email report",
  FIREBASE_MESSAGE_PHOTO_CAPTURE_SUCCESS: "Picture captured successfully",
  FIREBASE_MESSAGE_LICENSE_CAPTURE_SUCCESS: "License captured successfully",
  FIREBASE_MESSAGE_PHOTO_CAPTURE_REQUEST_SENT: "Picture capture request sent",
  REPORT_NO_RECORDS_MESSAGE: "No records found with provided filters.",
  REPORT_STATUS_SUCCESSFUL_MESSAGE: "Successful",
  CHECK_VISITOR_GUARDIAN_RESPONSE_TYPES: {
    INVALID_STUDENT_DETAILS: "invalid_student_details",
    VALID_STUDENT_DETAILS: "valid_student_details",
    VALID_STUDENT_NON_GUARDIAN_DETAILS: "valid_student_non_guardian_details",
    NON_MATCHING_RESPONSE: "non_matching_response"
  },
  CHECK_VISITOR_GUARDIAN_RESPONSE_MESSAGES: {
    INVALID_STUDENT_DETAILS: "Student details are invalid",
    VALID_STUDENT_DETAILS: "Student details are valid",
    VALID_STUDENT_NON_GUARDIAN_DETAILS:
      "Student details are valid but parents are non-guardian",
    NON_MATCHING_RESPONSE: "Non matching response"
  },
  FILTER_VISIT_STATUS_DENIED: "Denied",
  FILTER_VISIT_STATUS_SIGNED_IN: "Signed in",
  FILTER_VISIT_STATUS_SIGNED_OUT: "Signed out",
  ALERT_RESPONSE_EMPTY_LIST: "contact list is empty",
  MESSAGE_WHEN_SMS_CONTACT_LIST_EMPTY:
    "We were unable to send SMS alert as contact list is empty",
  MESSAGE_WHEN_EMAIL_CONTACT_LIST_EMPTY:
    "We were unable to send email alert as contact list is empty"
})

const VISITOR_ROLES = ["superadmin", "admin", "teacher", "staff"]
const VISITOR_ADMIN_ROLES = ["superadmin", "admin"]

const PATHS = Object.freeze({
  SETTINGS: "/visitor/settings",
  ADULTS: "/visitor/settings/adults",
  GUARDIAN_STUDENT_MATRIX: "/visitor/settings/guardian-student-matrix",
  SECURITY: "/visitor/settings/security",
  KIOSK: "/visitor/settings/kiosk",
  GENERAL: "/visitor/settings/general",
  LIST: "/visitor/list",
  MANAGEMENT: "/visitor/management",
  CHECK_IN_LOGS: "/visitor/management/check-ins-and-logs",
  PRINTER: "/visitor/management/printer"
})

const ROUTE_DEPENDENCY = Object.freeze({
  SETTINGS: "routerDependencies/hasVisitorSettingsRouteAccess",
  ADULTS: "routerDependencies/hasVisitorAdultListRouteAccess",
  GUARDIAN_STUDENT_MATRIX:
    "routerDependencies/hasVisitorGuardianStudentMatrixRouteAccess",
  SECURITY: "routerDependencies/hasVisitorSecuritySettingsRouteAccess",
  KIOSK: "routerDependencies/hasVisitorKioskSettingsRouteAccess",
  GENERAL: "routerDependencies/hasVisitorGeneralSettingsRouteAccess",
  LIST: "routerDependencies/hasVisitorListSettingsRouteAccess",
  MANAGEMENT: "routerDependencies/hasVisitorManagementRouteAccess",
  CHECK_IN_LOGS: "routerDependencies/hasVisitorCheckInsLogsRouteAccess",
  PRINTER: "routerDependencies/hasVisitorPrinterSetupRouteAccess"
})

export default {
  MODULE,
  VISITOR_MODULE_ID,
  ALERTS,
  SETTINGS,
  VALIDATIONS,
  PRINTER_SETUP,
  ALLOWED_VISITOR_SCHOOL_IDS,
  VISITOR_LOCAL_STORAGE_KEYS,
  VISITOR_CAMERA_TYPES,
  VISITOR_CAMERA_REQUEST_CATEGORY,
  VISITOR_DEFAULT_GENERAL_SETTINGS,
  PRINTER_CONFIG,
  PRINTER_MESSAGES,
  CHECK_IN_FORM,
  KIOSK_SETTINGS,
  LIST_SETTINGS,
  SECURITY_SETTINGS,
  VISITOR_ROLES,
  VISITOR_ADMIN_ROLES,
  PATHS,
  ROUTE_DEPENDENCY
}
