import moment from "moment"
import helpers from "./helpers"
import store from "./store/index"
import localforage from "localforage"
let wsReconnectInterval = null
const initDashboardSockets = () => {
  window.dashboardChannel.passesChannel.listen(
    "PassCommentCreatedEvent",
    (payload) => {
      //Update counters
      store.commit("dashboardCounters/UPDATE_PASS", payload.pass)
      //Update passes table
      store.commit("dashboardTable/UPDATE_PASS", payload.pass)
      if (payload.pass.type == "APT" && payload.pass.comments_count == 1) {
        return
      } else {
        helpers.checkForNotificationSound(payload)
      }
    }
  )

  window.dashboardChannel.appPassChannel.listen(
    "PassCommentCreatedEvent",
    (payload) => {
      if (payload && payload.pass) {
        const activeTab = store.getters["adultAptPass/activeTab"]
        const activeRoute = store.getters["routerDependencies/activeRoute"]

        if (activeRoute.name === "Dashboard" || activeTab == "nextSevenDays") {
          store.commit("adultAptPass/UPDATE_APP_PASS", payload.pass)
        }
      }
      helpers.checkForNotificationSound(payload)
    }
  )

  window.dashboardChannel.appPassChannel.listen(
    "AppointmentPassCreated",
    (payload) => {
      if (payload && payload.appointmentPass) {
        store.commit("dashboardCounters/UPDATE_APP_COUNTERS", [
          payload.appointmentPass
        ])
        let activeTab = store.getters["adultAptPass/activeTab"]
        const activeRoute = store.getters["routerDependencies/activeRoute"]

        if (
          activeRoute.name == "Dashboard" ||
          (helpers.isAptInSelectedTab(payload.appointmentPass) &&
            (activeTab == "nextSevenDays" ||
              (activeTab =
                "prevSevenDays" &&
                !payload.appointmentPass.recurrence_appointment_pass)))
        ) {
          store.commit(
            "adultAptPass/PUSH_APPOINTMENT_PASS",
            payload.appointmentPass
          )
        }
      }
    }
  )
  window.dashboardChannel.appPassChannel.listen(
    "AppointmentPassCancelled",
    (payload) => {
      if (payload && payload.appointmentPass) {
        store.commit("dashboardCounters/UPDATE_APP_COUNTERS", [
          payload.appointmentPass
        ])
        let activeTab = store.getters["adultAptPass/activeTab"]
        const activeRoute = store.getters["routerDependencies/activeRoute"]

        if (
          activeRoute.name == "Dashboard" ||
          (helpers.isAptInSelectedTab(payload.appointmentPass) &&
            (activeTab == "nextSevenDays" ||
              (activeTab =
                "prevSevenDays" &&
                !payload.appointmentPass.recurrence_appointment_pass)))
        ) {
          store.commit("adultAptPass/UPDATE_APP_PASS", payload.appointmentPass)
        }
      }
    }
  )

  window.dashboardChannel.appPassChannel.listen(
    "AppointmentPassUpdated",
    (payload) => {
      store.commit("dashboardCounters/UPDATE_APP_COUNTERS", [
        payload.appointmentPass
      ])
      const activePassType = store.getters["dashboardTable/getType"]
      const activeTab = store.getters["adultAptPass/activeTab"]
      if (
        (activePassType.includes("appointment") &&
          activeTab == "nextSevenDays") ||
        (!activePassType.includes("appointment") &&
          payload &&
          payload.appointmentPass)
      ) {
        store.commit("adultAptPass/UPDATE_APP_PASS", payload.appointmentPass)
      }
    }
  )
  window.dashboardChannel.appPassChannel.listen(
    "RecurrenceAppointmentPassCreated",
    (payload) => {
      const activePassType = store.getters["dashboardTable/getType"]
      const activeTab = store.getters["adultAptPass/activeTab"]
      if (
        activePassType.includes("appointment") &&
        activeTab == "prevSevenDays" &&
        payload &&
        payload.recurrenceAppointmentPass
      ) {
        store.commit(
          "adultAptPass/PUSH_APPOINTMENT_PASS",
          payload.recurrenceAppointmentPass
        )
      }
    }
  )

  window.dashboardChannel.appPassChannel.listen(
    "RecurrenceAppointmentPassEdited",
    (payload) => {
      const activePassType = store.getters["dashboardTable/getType"]
      const activeTab = store.getters["adultAptPass/activeTab"]
      if (
        activePassType.includes("appointment") &&
        activeTab == "prevSevenDays" &&
        payload &&
        payload.recurrenceAppointmentPass
      ) {
        store.commit(
          "adultAptPass/UPDATE_APP_PASS",
          payload.recurrenceAppointmentPass
        )
      }
    }
  )
  window.dashboardChannel.appPassChannel.listen(
    "AppointmentPassMissed",
    (payload) => {
      const activePassType = store.getters["dashboardTable/getType"]
      const activeTab = store.getters["adultAptPass/activeTab"]
      if (
        activePassType.includes("appointment") &&
        activeTab == "nextSevenDays" &&
        payload &&
        payload.appointmentPass
      ) {
        store.commit("adultAptPass/UPDATE_APP_PASS", payload.appointmentPass)
      }
    }
  )
  window.dashboardChannel.appPassChannel.listen(
    "AppointmentPassesDeleted",
    (payload) => {
      const activePassType = store.getters["dashboardTable/getType"]
      const activeTab = store.getters["adultAptPass/activeTab"]
      if (
        activePassType.includes("appointment") &&
        activeTab == "nextSevenDays" &&
        payload &&
        payload.appointmentIdsForDelete
      ) {
        store.commit(
          "adultAptPass/DELETE_APTS_BY_ID",
          payload.appointmentIdsForDelete
        )
        store.commit(
          "dashboardCounters/DELETE_APP_COUNTERS_BY_ID",
          payload.appointmentIdsForDelete
        )
      }
    }
  )

  window.dashboardChannel.appPassChannel.listen(
    "AppointmentPassEnded",
    (payload) => {
      const activePassType = store.getters["dashboardTable/getType"]
      const activeTab = store.getters["adultAptPass/activeTab"]
      if (
        activePassType.includes("appointment") &&
        activeTab == "nextSevenDays" &&
        payload &&
        payload.appointmentPass
      ) {
        store.commit("adultAptPass/UPDATE_APP_PASS", payload.appointmentPass)
      }
    }
  )

  window.dashboardChannel.schoolChannel.listen(
    "PassMinTimeReached",
    (payload) => {
      //Update counters
      store.commit("dashboardCounters/UPDATE_PASS", payload.pass)
      //Update passes table
      store.commit("dashboardTable/UPDATE_PASS", payload.pass)
    }
  )
  window.dashboardChannel.schoolChannel.listen(
    "AdminPassApproved",
    (payload) => {
      //Update counters
      store.commit("dashboardCounters/UPDATE_PASS", payload.pass)
      //Update passes table
      store.commit("dashboardTable/UPDATE_PASS", payload.pass)
      helpers.checkForNotificationSound(payload)
    }
  )
  window.dashboardChannel.schoolChannel.listen(
    "AdminPassArrived",
    (payload) => {
      //Update counters
      store.commit("dashboardCounters/UPDATE_PASS", payload.pass)
      //Update passes table
      store.commit("dashboardTable/UPDATE_PASS", payload.pass)
    }
  )
  window.dashboardChannel.schoolChannel.listen("AdminPassInOut", (payload) => {
    //Update counters
    store.commit("dashboardCounters/UPDATE_PASS", payload.pass)
    //Update passes table
    store.commit("dashboardTable/UPDATE_PASS", payload.pass)
  })
  window.dashboardChannel.schoolChannel.listen(
    "AdminPassReturning",
    (payload) => {
      //Update counters
      store.commit("dashboardCounters/UPDATE_PASS", payload.pass)
      //Update passes table
      store.commit("dashboardTable/UPDATE_PASS", payload.pass)
    }
  )
  window.dashboardChannel.schoolChannel.listen("AdminPassEnded", (payload) => {
    //Update counters
    store.commit("dashboardCounters/UPDATE_PASS", payload.pass)
    //Update passes table
    store.commit("dashboardTable/UPDATE_PASS", payload.pass)
  })
  window.dashboardChannel.schoolChannel.listen("PassCanceled", (payload) => {
    //Update counters
    store.commit("dashboardCounters/UPDATE_PASS", payload.pass)
    //Update passes table
    store.commit("dashboardTable/UPDATE_PASS", payload.pass)
  })
  window.dashboardChannel.schoolChannel.listen(
    "AdminPassCanceled",
    (payload) => {
      //Update counters
      store.commit("dashboardCounters/UPDATE_PASS", payload.pass)
      //Update passes table
      store.commit("dashboardTable/UPDATE_PASS", payload.pass)
    }
  )
  window.dashboardChannel.schoolChannel.listen(
    "AdminPassExpired",
    (payload) => {
      //Update counters
      store.commit("dashboardCounters/UPDATE_PASS", payload.pass)
      //Update passes table
      store.commit("dashboardTable/UPDATE_PASS", payload.pass)
    }
  )
  window.dashboardChannel.schoolChannel.listen(
    "AdminPassCreated",
    (payload) => {
      //Update counters
      store.commit("dashboardCounters/UPDATE_PASS", payload.pass)
      //Update passes table
      store.commit("dashboardTable/UPDATE_PASS", payload.pass)
      helpers.checkForNotificationSound(payload)
    }
  )
}
// eslint-disable-next-line
const destroyDashboardSockets = () => {
  if (process.env.VUE_APP_SOCKET_PROVIDER == "PUSHER") {
    window.dashboardChannel.appPassChannel.subscription.unbind_all()
    window.dashboardChannel.schoolChannel.subscription.unbind_all()
    window.dashboardChannel.passesChannel.subscription.unbind_all()
    window.dashboardChannel.dashboardChannel.subscription.unbind_all()
  } else {
    window.dashboardChannel.appPassChannel.unbind()
    window.dashboardChannel.schoolChannel.unbind()
    window.dashboardChannel.passesChannel.unbind()
    window.dashboardChannel.dashboardChannel.unbind()
  }
}
const attachSocketListeners = (socket, state) => {
  const id = state.authentication.user.id
  const role = state.authentication.user.role.name
  const schoolId = state.authentication.user.school_id
  const isMuted = !state.authentication.user.audio_enabled
  const profileChannel = socket.private(`user.profile.${id}`)

  Object.values(socket.connector.channels).forEach((channel) => {
    channel.on("subscription_error", (error) => {
      if (error == 401) {
        destroySocketReconnectConditions(socket)
        store.dispatch("authentication/logOut")
      }
    })
  })

  if (role == "student") {
    const studentPassLimits = socket.private(`pass-limits.${schoolId}`)
    const studentAppPassChannel = socket.private(
      `user.appointments.passes.${id}.${schoolId}`
    )
    const studentNowPassChannel = socket.private(
      `user.passes.${id}.${schoolId}`
    )
    const favUnavailableChannel = socket.private(
      `student.unavailables.${schoolId}`
    )
    const passBlockChannel = socket.private(`pass-blocks.${schoolId}`)
    window.studentChannels = {
      profileChannel,
      studentPassLimits
    }

    studentAppPassChannel.listen("AppointmentPassRemind", (payload) => {
      if (state.authentication.user.audio_enabled) {
        helpers.playNotificationSound()
      }
      store.commit(
        "sockets/SET_APPOINTMENT_PASS_REMIND",
        payload.appointmentPass
      )
      store.dispatch("sockets/getNotifications")
    })

    studentAppPassChannel.listen("AppointmentPassRun", (payload) => {
      if (state.authentication.user.audio_enabled) {
        helpers.playNotificationSound()
      }
      store.commit("sockets/SET_APPOINTMENT_PASS_RUN", payload.appointmentPass)
      store.commit("studentAptPass/UPDATE_APP_PASS", payload.appointmentPass)
      store.commit("sockets/REMOVE_NOTIFICATION", "appointmentPassRemind")
      store.dispatch("sockets/getNotifications")
    })

    studentAppPassChannel.listen("AppointmentPassCancelled", (payload) => {
      if (state.authentication.user.audio_enabled) {
        helpers.playNotificationSound()
      }
      store.dispatch("sockets/getNotifications")
      let activeTab = store.getters["studentAptPass/activeTab"]
      if (
        helpers.isAptInSelectedTabStudent(payload.appointmentPass) &&
        (activeTab == "nextSevenDays" ||
          (activeTab =
            "prevSevenDays" &&
            !payload.appointmentPass.recurrence_appointment_pass)) &&
        payload &&
        payload.appointmentPass
      ) {
        store.commit("studentAptPass/UPDATE_APP_PASS", payload.appointmentPass)
        store.commit("studentAptPass/UPDATE_TODAYS_APP", [
          payload.appointmentPass
        ])
      }
    })
    studentAppPassChannel.listen("AppointmentPassTimeChanged", (payload) => {
      if (state.authentication.user.audio_enabled) {
        helpers.playNotificationSound()
      }
      store.dispatch("sockets/getNotifications")
      if (
        payload &&
        payload.instance !== "App\\Models\\RecurrenceAppointmentPass"
      ) {
        store.commit("studentAptPass/UPDATE_APP_PASS", payload.appointmentPass)
        store.commit("studentAptPass/UPDATE_TODAYS_APP", [
          payload.appointmentPass
        ])
      }
    })
    studentAppPassChannel.listen(
      "RecurrenceAppointmentPassCreated",
      (payload) => {
        const activeTab = store.getters["studentAptPass/activeTab"]
        if (
          activeTab == "prevSevenDays" &&
          payload &&
          payload.recurrenceAppointmentPass
        ) {
          store.commit(
            "studentAptPass/UPDATE_APP_PASS",
            payload.recurrenceAppointmentPass
          )
        }
      }
    )

    studentAppPassChannel.listen(
      "RecurrenceAppointmentPassEdited",
      (payload) => {
        const activeTab = store.getters["studentAptPass/activeTab"]
        if (
          activeTab == "prevSevenDays" &&
          payload &&
          payload.recurrenceAppointmentPass
        ) {
          store.commit(
            "studentAptPass/UPDATE_APP_PASS",
            payload.recurrenceAppointmentPass
          )
        }
      }
    )
    studentAppPassChannel.listen("AppointmentPassCreated", (payload) => {
      store.commit("studentAptPass/UPDATE_TODAYS_APP", [
        payload.appointmentPass
      ])
      let activeTab = store.getters["studentAptPass/activeTab"]
      if (
        helpers.isAptInSelectedTabStudent(payload.appointmentPass) &&
        (activeTab == "nextSevenDays" ||
          (activeTab =
            "prevSevenDays" &&
            !payload.appointmentPass.recurrence_appointment_pass)) &&
        payload &&
        payload.appointmentPass
      ) {
        store.commit("studentAptPass/UPDATE_APP_PASS", payload.appointmentPass)
      }
    })
    studentAppPassChannel.listen("AppointmentPassUpdated", (payload) => {
      const activeTab = store.getters["studentAptPass/activeTab"]
      if (activeTab == "nextSevenDays" && payload && payload.appointmentPass) {
        store.commit("studentAptPass/UPDATE_APP_PASS", payload.appointmentPass)
        store.commit("studentAptPass/UPDATE_TODAYS_APP", [
          payload.appointmentPass
        ])
      }
    })
    studentAppPassChannel.listen("AppointmentPassesDeleted", (payload) => {
      const activeTab = store.getters["studentAptPass/activeTab"]
      if (
        activeTab == "nextSevenDays" &&
        payload &&
        payload.appointmentIdsForDelete
      ) {
        store.commit(
          "studentAptPass/DELETE_APTS_BY_ID",
          payload.appointmentIdsForDelete
        )
        store.commit(
          "studentAptPass/DELETE_TODAYS_APP_BY_ID",
          payload.appointmentIdsForDelete
        )
      }
    })
    studentAppPassChannel.listen("PassCommentCreatedEvent", (payload) => {
      if (payload && payload.pass) {
        store.commit("studentAptPass/UPDATE_APP_PASS", payload.pass)
      }
    })

    studentNowPassChannel.listen("PassApproved", (payload) => {
      if (!isMuted && payload.notifyStudent) {
        helpers.playNotificationSound()
      }
      store.commit("passes/SET_ACTIVE_PASS", payload.pass)
      if (window.vueRouter.currentRoute.path !== "/passes/activepass") {
        window.vueRouter.push("/passes/activepass")
      }
    })

    studentNowPassChannel.listen("PassMinTimeReachedStudent", (payload) => {
      store.commit("passes/SET_ACTIVE_PASS", payload.pass)
      if (window.vueRouter.currentRoute.path !== "/passes/activepass") {
        window.vueRouter.push("/passes/activepass")
      }
    })

    studentNowPassChannel.listen("PassCanceled", (payload) => {
      store.commit("passes/SET_ACTIVE_PASS", payload.pass)
      if (window.vueRouter.currentRoute.path !== "/passes/activepass") {
        window.vueRouter.push("/passes/activepass")
      }
    })
    studentNowPassChannel.listen("PassCreated", (payload) => {
      if (payload.notifyStudent && !isMuted) {
        helpers.playNotificationSound()
      }
      store.commit("passes/SET_ACTIVE_PASS", payload.pass)
      const params = {
        from_id: payload.pass.from_id,
        from_type: payload.pass.from_type,
        to_id: payload.pass.to_id,
        to_type: payload.pass.to_type
      }
      store.dispatch("passes/getActiveFlags", params)
      if (window.vueRouter.currentRoute.path !== "/passes/activepass") {
        window.vueRouter.push("/passes/activepass")
      }
    })

    studentNowPassChannel.listen("PassExpired", (payload) => {
      store.commit("passes/SET_ACTIVE_PASS", payload.pass)
      if (window.vueRouter.currentRoute.path !== "/passes/activepass") {
        window.vueRouter.push("/passes/activepass")
      }
    })

    studentNowPassChannel.listen("PassArrived", (payload) => {
      store.commit("passes/SET_ACTIVE_PASS", payload.pass)
      if (window.vueRouter.currentRoute.path !== "/passes/activepass") {
        window.vueRouter.push("/passes/activepass")
      }
    })
    studentNowPassChannel.listen("PassEnded", (payload) => {
      store.commit("passes/SET_ACTIVE_PASS", payload.pass)
      if (window.vueRouter.currentRoute.path !== "/passes/activepass") {
        window.vueRouter.push("/passes/activepass")
      }
    })
    studentNowPassChannel.listen("PassInOut", (payload) => {
      store.commit("passes/SET_ACTIVE_PASS", payload.pass)
      if (window.vueRouter.currentRoute.path !== "/passes/activepass") {
        window.vueRouter.push("/passes/activepass")
      }
    })
    studentNowPassChannel.listen("PassReturning", (payload) => {
      store.commit("passes/SET_ACTIVE_PASS", payload.pass)
      if (window.vueRouter.currentRoute.path !== "/passes/activepass") {
        window.vueRouter.push("/passes/activepass")
      }
    })
    studentNowPassChannel.listen("PassCommentCreatedEvent", (payload) => {
      if (state.authentication.user.audio_enabled) {
        helpers.playNotificationSound()
      }
      store.commit("passes/SET_ACTIVE_PASS", payload.pass)
      if (window.vueRouter.currentRoute.path !== "/passes/activepass") {
        window.vueRouter.push("/passes/activepass")
      }
    })

    studentNowPassChannel.listen("PinStatusEvent", (payload) => {
      const pinAttempts = payload.pinAttempts
      if (pinAttempts === 0) {
        store.commit("authentication/RESET_PIN_ATTEMPTS_COUNT")
      } else if (pinAttempts === 3) {
        store.dispatch("authentication/logOut").then(() => {
          window.location.reload()
        })
      } else if (pinAttempts === 5) {
        store
          .dispatch("authentication/getUserDetails", {
            clearCacheEntry: true
          })
          .then(() => {
            store.commit("authentication/SET_USER_IS_LOCKED", true)
            window.vueRouter.push("/locked")
          })
      }
    })

    profileChannel.listen("UserUnlockedEvent", () => {
      store
        .dispatch("authentication/getUserDetails", {
          clearCacheEntry: true
        })
        .then(() => {
          store.commit("authentication/RESET_PIN_ATTEMPTS_COUNT")
          window.vueRouter.push("/passes/activepass")
          store.commit("authentication/SET_USER_IS_LOCKED", false)
        })
    })

    passBlockChannel.listen("PassBlockRunnedEvent", (payload) => {
      store.commit("passBlocks/SET_ACTIVE_PASS_BLOCK", payload.passBlock)
    })

    favUnavailableChannel.listen("FavoriteUnavailableEvent", (payload) => {
      if (
        payload.forFavorite &&
        payload.forFavorite.type === "App\\Models\\User"
      ) {
        store.commit(
          "favorites/UPDATE_FAVORITE_UNAVAILABILITY",
          payload.forFavorite
        )
      }
    })
  } else {
    const appPassChannel = socket.private(`appointments.passes.${schoolId}`)
    const schoolChannel = socket.private(`school.passes.${schoolId}`)
    const passesChannel = socket.private(`passes.${schoolId}`)
    const dashboardChannel = socket.private(`dashboard-stats.${id}.${schoolId}`)

    window.dashboardChannel = {
      appPassChannel,
      schoolChannel,
      passesChannel,
      dashboardChannel,
      profileChannel
    }
    initDashboardSockets()

    const schoolDataChannel = socket.private(`school.${schoolId}`)

    schoolDataChannel.listen("ProvideUsersByApiOnlyToggledEvent", () => {
      store.dispatch("schools/getSchools", {
        clearCacheEntry: true
      })
      window.lf.dropInstance()
      localforage.clear()
    })

    const unavailablesChannel = socket.private(`user.unavailables.${id}`)

    unavailablesChannel.listen("UnavailableUpdate", () => {
      store.dispatch("authentication/getUserInitialUnavailability")
    })

    unavailablesChannel.listen("UnavailableExpired", () => {
      store.commit("authentication/SET_USER_UNAVAILABLE", null)
      store.commit("authentication/SET_IS_USER_UNAVAILABLE", null)
      store.commit("dashboardTable/SET_UNAVAILABLE", null)
    })

    if (role == "superadmin" || role == "admin") {
      const summaryReportChannel = socket.private(
        `user.summary.report.request.${id}.${schoolId}`
      )

      summaryReportChannel.listen(
        "NotificationToDownloadSummaryReportRequestEvent",
        () => {
          if (state.authentication.user.audio_enabled) {
            helpers.playNotificationSound()
          }
          store.dispatch("sockets/getNotifications")
        }
      )
    }
  }
  const clearCacheCannel = socket.private(`caching.${schoolId}`)
  const passBlockChannel = socket.private(`pass-blocks.${schoolId}`)
  const modulesChannel = socket.private(`modules.${schoolId}`)
  const autheChannel = socket.private(`users.authentication.${schoolId}`)
  const changeSchool = socket.private(`schools.actions.${id}`)
  const tardyLaddersChannel = socket.private(`tardy.ladders.${schoolId}`)

  const schoolPins = socket.private(`school-pins.${schoolId}`)

  const dashboardPassLimitChannel = socket.private(
    `dashboard-pass-limits.${schoolId}`
  )

  const archivedUserChannel = socket.private(
    `user.authentication.${schoolId}.${id}`
  )

  archivedUserChannel.listen("LogoutUserEvent", () => {
    socket.disconnect()
    store.dispatch("authentication/unauthorizeArchivedUser")
  })

  schoolPins.listen("PinChangedEvent", (payload) => {
    if (payload) {
      store.commit("rooms/SET_USER_ASSIGN_ROOMS_CHANGE_PIN", payload)
      store.commit("rooms/SET_USER_ASSIGN_ROOMS_LISENER", payload)
    }
  })

  dashboardPassLimitChannel.listen("ActivePassLimitChanged", (payload) => {
    if (payload) {
      store.commit("activePass/SET_ACTIVE_PASS_LIMITS_LISENER", payload)
    }
  })

  dashboardPassLimitChannel.listen("LimitLocationEvent", (payload) => {
    if (payload) {
      store.commit("dashboardTable/UPDATE_AUTO_PASS_LIMIT_LISTENER", payload)
    }
  })

  tardyLaddersChannel.listen("Tardy\\MaxLadderStepsReached", (payload) => {
    store.commit(
      "exceededIncidents/SET_EXCEEDED_INCIDENTS",
      payload.maxStepsReached
    )
  })

  const passcodeImportsChannel = socket.private(
    `schools.file.imports.${schoolId}.${id}`
  )
  const modulesByUserChannel = socket.private(`modules.${schoolId}.${id}`)

  modulesByUserChannel.listen("Tardy\\LatePassCreationToggled", () => {
    store.dispatch("schools/getActiveModules", {
      clearCacheEntry: true
    }),
      store.dispatch("authentication/getUserDetails", {
        clearCacheEntry: true
      })
  })

  profileChannel.listen("ProfileUpdatedEvent", (payload) => {
    store.commit("authentication/SET_USER", payload.user)
  })

  passBlockChannel.listen("PassBlockCreated", (payload) => {
    store.commit("passBlocks/PUSH_PASS_BLOCK", payload.passBlock)
    const now = moment().format("DD/MM/yyyy HH:mm:ss")
    if (
      moment(payload.passBlock.from_date).format("DD/MM/YYYY HH:mm:ss") <=
        now &&
      moment(payload.passBlock.to_date).format("DD/MM/yyyy HH:mm") >= now
    ) {
      store.commit("dashboardTable/SET_PASS_BLOCK", payload.passBlock)
    }
  })
  passBlockChannel.listen("PassBlockRemoved", (payload) => {
    if (payload) {
      store.commit("passBlocks/DELETE_PASS_BLOCK", payload.passBlockId)
    }
  })
  modulesChannel.listen("ModuleChanged", () => {
    store.dispatch("schools/getActiveModules", {
      clearCacheEntry: true
    })
  })
  modulesChannel.listen("Tardy\\FeatureTierChanged", () => {
    store
      .dispatch("schools/getSchools", {
        clearCacheEntry: true
      })
      .then(() => {
        store.dispatch("schools/getActiveModules", {
          clearCacheEntry: true
        })
      })
  })
  autheChannel.listen("LogoutEvent", () => {
    socket.disconnect()
    store.dispatch("authentication/logOut")
  })
  changeSchool.listen("SchoolChangeEvent", () => {
    store
      .dispatch("authentication/getUserDetails", {
        clearCacheEntry: true
      })
      .then(() => {
        store.dispatch("schools/getSchools", {
          clearCacheEntry: true
        })
        if (role == "student") {
          store.dispatch("schools/getActiveModules", {
            clearCacheEntry: true
          })
          store.dispatch("favorites/getFavorites", {
            clearCacheEntry: true
          })
        } else {
          store.dispatch("authentication/getUserSettings", {
            clearCacheEntry: true
          })
        }
        window.vueRouter.push("/dashboard")
        window.location.reload()
      })
  })
  clearCacheCannel.listen("ClearFrontEndCacheEvent", () => {
    window.lf.dropInstance()
    localforage.clear()
  })
  clearCacheCannel.listen("AssignedLocationsUpdatedEvent", (payload) => {
    store.commit(
      "dashboardTable/SET_USERS_ASSIGNED_ROOMS",
      payload.userIdsAssigned
    )
    const currentRole = store.getters["authentication/getUserRole"]
    if (currentRole == "student") {
      store.dispatch("passes/getStudentActivePass")
    }
  })

  passcodeImportsChannel.listen("KiosksUserFileProcessedEvent", (payload) => {
    store.commit("kiosks/SET_KIOSK_PASSCODES_NAME", payload)
  })

  passcodeImportsChannel.listen("Files\\FileCompletedEvent", (payload) => {
    if (payload.file) {
      store.commit(
        "users/SET_STUDENT_CARDS_LINK",
        payload.file.download_url || null
      )
    }
  })

  const preferencesChannel = socket.private(`user.preferences.${schoolId}`)

  preferencesChannel.listen("UserProfilePreferencesEvent", () => {
    store.dispatch("favorites/getFavorites", { clearCacheEntry: true })
  })
}
const newSocketConection = (socket) => {
  socket.disconnect()
  socket.connect()
  attachSocketListeners(socket, store.state)
}
const handlePageRefocusWSConnection = () => {
  if (store.state.authentication.user && store.state.authentication.user.id) {
    !document.hidden && newSocketConection(window.EchoInstance)
  }
}
const initSocketReconnectConditions = (socket) => {
  window.addEventListener("visibilitychange", handlePageRefocusWSConnection) // reconnect when tab is re-focused

  wsReconnectInterval = setInterval(
    () => {
      if (
        store.state.authentication.user &&
        store.state.authentication.user.id
      ) {
        newSocketConection(socket)
      }
    },
    1000 * 60 * 120
  ) // reconnect every 2 hours
}
const destroySocketReconnectConditions = (socket) => {
  socket.disconnect()
  window.removeEventListener("visibilitychange", handlePageRefocusWSConnection)
  if (wsReconnectInterval) {
    clearInterval(wsReconnectInterval)
  }
}
export function WebSocketPlugin(socket) {
  return (store) => {
    // called when the store is initialized
    let isSubscribed = false

    store.subscribe((mutation, state) => {
      if (mutation.type === "kiosks/SET_ACTIVE_KIOSK") {
        destroySocketReconnectConditions(socket)
      }
      if (mutation.type == "authentication/SET_USER" && !isSubscribed) {
        if (state && state.authentication) {
          if (state.authentication.user && state.authentication.user.id) {
            newSocketConection(socket)
            initSocketReconnectConditions(socket)
            isSubscribed = true
          } else {
            socket.disconnect()
          }
        }
      }
    })
  }
}

export default {
  WebSocketPlugin
}
