import "core-js/stable"
import CoreuiVue from "@coreui/vue-pro"
import App from "./App"
import router from "./router/index"
import store from "./store/index"
//TODO:Fix translations
// import i18n from "./i18n.js";
import axios from "axios"
import helpers from "./helpers/index"
import download from "./helpers/downloadCSV"
import CIcon from "@coreui/icons-vue"
import SidebarIcon from "@/v3components/Sidebar/SidebarIcon.vue"
import { iconsSet as icons } from "@/assets/icons"
import VueSelect from "vue-select"
import { library } from "@fortawesome/fontawesome-svg-core"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import CustomTimePicker from "@/v3components/shared/Form/CustomTimePickerOld"
import DataTable from "@/v3components/shared/DataTable/DataTable"
import DataTableOptimized from "@/v3components/shared/DataTable/DataTableOptimized"
import CustomScrollbar from "@/v3components/CustomScrollbar"
import CCheckBox from "@/v3components/shared/Form/CCheckBoxOld"
import datePicker from "vue-bootstrap-datetimepicker"
import PerfectScrollbar from "vue3-perfect-scrollbar"
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css"
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css"
import { version } from "../package"
import moment from "moment-timezone"
import tokenService from "@/helpers/tokenService.js"
import { createApp } from "vue"
import { setupCalendar, Calendar, DatePicker } from "v-calendar"
import "v-calendar/dist/style.css"
import VueVirtualScroller from "vue-virtual-scroller"
import "vue-virtual-scroller/dist/vue-virtual-scroller.css"
import Pagination from "@/v3components/shared/DataTable/PaginationOld.vue"
import VueDatePicker from "@vuepic/vue-datepicker"
import modal from "./plugins/modal"
import actionDialog from "./plugins/actionDialog"
import InOutColumn from "@/v3components/shared/PassComponents/InOutColumn.vue"

library.add(fas)

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error?.response?.status === 401) {
      store.dispatch("authentication/unauthorize")
      router.push("/login")
      return Promise.reject(error)
    }
    return Promise.reject(error)
  }
)

const init = () => {
  const app = createApp(App)
  app.use(router)
  app.use(store)
  app.use(VueVirtualScroller)
  // TODO:  Fix  localization
  //app.use(i18n);
  app.use(datePicker)
  app.use(CoreuiVue)
  app.use(PerfectScrollbar)
  app.use(modal)
  app.use(actionDialog)
  app.provide("icons", icons)
  app.component("CIcon", CIcon)
  app.component("SidebarIcon", SidebarIcon)
  app.component("FontAwesomeIcon", FontAwesomeIcon)
  app.component("CustomTimePicker", CustomTimePicker)
  app.component("CCheckBox", CCheckBox)
  app.component("DataTable", DataTable)
  app.component("DataTableOptimized", DataTableOptimized)
  app.component("CustomScrollbar", CustomScrollbar)
  app.component("VSelect", VueSelect)
  app.use(setupCalendar, {})
  app.component("Calendar", Calendar)
  app.component("DatePicker", DatePicker)
  app.component("Pagination", Pagination)
  app.component("InOutColumn", InOutColumn)
  app.component("VueDatePicker", VueDatePicker)
  app.config.globalProperties.$helpers = helpers
  app.config.globalProperties.$download = download
  app.directive("noautofill", {
    // When the bound element is inserted into the DOM...
    bind: function (el) {
      const elem = el.childNodes[1]
      elem.setAttribute("readonly", true)
      elem.setAttribute("autocomplete", "new-password")
      elem.addEventListener("focus", (event) => {
        event.target.removeAttribute("readonly")
      })
    }
  })
  app.directive("noautofillinput", {
    // When the bound element is inserted into the DOM...
    bind: function (el) {
      el.setAttribute("readonly", true)
      el.setAttribute("autocomplete", "new-password")
      el.addEventListener("focus", (event) => {
        event.target.removeAttribute("readonly")
      })
    }
  })
  app.mount("#app")
}
clearInterval(window.globalTimeInterval)
window.globalTimeInterval = setInterval(() => {
  store.commit("layout/setGlobalTime", moment().format())
}, 1000)

//RUM
if (process.env.VUE_APP_DD_ENV == "production") {
  window.DD_RUM &&
    window.DD_RUM.init({
      applicationId: "5af5d426-58bd-4c5d-bd3a-28be4a81b6c9",
      clientToken: "pubb2923bd9d3a6071966fcf66a6d1ed9c2",
      site: "datadoghq.com",
      service: "ehp-v2",
      env: "prod",
      // Specify a version number to identify the deployed version of your application in Datadog
      version: version,
      sampleRate: 1,
      replaySampleRate: 0,
      trackInteractions: true,
      usePartitionedCrossSiteSessionCookie: true
    })
  document.addEventListener("contextmenu", function (e) {
    e.preventDefault()
  })
}
// Get user data before init if user is logged in
// Function witch check if session is expired
function handleVisibilityChange() {
  const sessionEnd = localStorage.getItem("sessionEnd")
  if (
    !document.hidden &&
    sessionEnd &&
    moment().isAfter(moment(sessionEnd)) &&
    !["/kiosk/login", "/kiosk/launch", "/login"].includes(
      window.location.pathname
    )
  ) {
    store.dispatch("authentication/logOut")
    localStorage.removeItem("accessToken")
    localStorage.removeItem("devAccessToken")
    localStorage.removeItem("logoutTime")
    localStorage.removeItem("sessionEnd")
  }
}
// Check for visibility change
document.addEventListener("visibilitychange", handleVisibilityChange)
// After user return to page after browser is minimize check if session is expired and rerun visibility listener
window.addEventListener(
  "focus",
  () => {
    handleVisibilityChange()
    document.addEventListener("visibilitychange", handleVisibilityChange)
  },
  false
)

if (tokenService.getToken()) {
  store.dispatch("authentication/getUserDetails").finally(() => {
    store.dispatch("authentication/getAudioPreferences")
    store.dispatch("dashboardTable/getUsersAssignedRooms")

    const user = store.getters["authentication/user"]
    if (user && user.role_id !== 1) {
      store.dispatch("authentication/getUserInitialUnavailability")
    }
    store.dispatch("schools/getSchools").then(() => {
      store.dispatch("schools/getActiveModules").then(() => {
        init()
      })
    })
  })
} else {
  init()
}
