<template>
  <CContainer>
    <CRow class="justify-content-center">
      <CCol md="12" class="text-start px-0">
        <CForm
          class="mb-2 max-w-500 m-auto"
          data-test-id="adult-create-app-pass-form"
        >
          <CRow>
            <CAlert
              v-if="reqResponse.message && !isMultiple"
              class="mt-1 w-100 mx-3"
              data-test-id="create-app-pass-form-alert-msg"
              :color="reqResponse.type"
              :show="!!reqResponse.message"
            >
              {{ reqResponse.message }}
            </CAlert>
            <CCol sm="12">
              <LazyLoadSelect
                :classes="[
                  {
                    'is-invalid':
                      v$.form.user_id.$error ||
                      reqResponse.errors[
                        'appointments.' + formIndex + '.user_id'
                      ] ||
                      reqResponse.errors['user_id']
                  },
                  'mb-3'
                ]"
                :is-multiple="false"
                :selected="form.user_id"
                type="student"
                placeholder="Search students"
                data-test-id="create-app-pass-form-student"
                :disabled="
                  editablePass && editablePass.has_appointment_detention
                "
                @changed="
                  (value) => {
                    form.user_id = value
                    checkForExistApp()
                  }
                "
              >
                <template #footer>
                  <div
                    v-if="v$.form.$error && v$.form.user_id.required.$invalid"
                    class="invalid-feedback"
                  >
                    This field is required
                  </div>
                  <div
                    v-if="
                      reqResponse.errors[
                        'appointments.' + formIndex + '.user_id'
                      ]
                    "
                    class="invalid-feedback"
                  >
                    {{
                      reqResponse.errors[
                        "appointments." + formIndex + ".user_id"
                      ][0]
                    }}
                  </div>
                  <div
                    v-if="reqResponse.errors['user_id']"
                    class="invalid-feedback"
                  >
                    {{ reqResponse.errors["user_id"][0] }}
                  </div>
                </template>
              </LazyLoadSelect>
            </CCol>
          </CRow>

          <CRow>
            <CCol sm="4" class="pe-auto pe-sm-0">
              <div v-if="!editablePassDate || isForDateEditable">
                <DatePicker
                  v-model="v$.form.for_date.$model"
                  :masks="{ input: 'MM/DD/YYYY' }"
                  class="cs-date-picker"
                  placeholder="Date"
                  :min-date="minDate"
                  :max-date="maxDate"
                  @update:model-value="
                    formatMontlyWeekDay(form.for_date), checkForExistApp()
                  "
                >
                  <template #default="{ inputValue, togglePopover }">
                    <div
                      class="input-icon-field"
                      @click="showForDateCalendar(togglePopover)"
                    >
                      <div class="form-group">
                        <input
                          class="form-control"
                          :class="{
                            'is-invalid':
                              v$.form.for_date.$error ||
                              reqResponse.errors[
                                'appointments.' + formIndex + '.for_date'
                              ] ||
                              reqResponse.errors['for_date']
                          }"
                          placeholder="Date"
                          readonly
                          :value="inputValue"
                        />
                        <div
                          v-if="
                            v$.form.$error && v$.form.for_date.required.$invalid
                          "
                          class="invalid-feedback"
                        >
                          This field is required
                        </div>
                        <div
                          v-if="
                            reqResponse.errors[
                              'appointments.' + formIndex + '.for_date'
                            ]
                          "
                          class="invalid-feedback"
                        >
                          {{
                            reqResponse.errors[
                              "appointments." + formIndex + ".for_date"
                            ][0]
                          }}
                        </div>
                        <div
                          v-if="reqResponse.errors['for_date']"
                          class="invalid-feedback"
                        >
                          {{ reqResponse.errors["for_date"][0] }}
                        </div>
                      </div>
                      <span class="field-icon">
                        <i class="fi flaticon-calendar me-1" />
                      </span>
                    </div>
                  </template>
                </DatePicker>
              </div>
              <div v-else class="form-group">
                <input
                  type="text"
                  disabled
                  class="form-control"
                  :value="formatedForDate"
                />
              </div>
            </CCol>
            <CCol sm="4" v-if="!editablePass || editablePass.period">
              <VSelect
                v-if="periods"
                v-model="form.period_id"
                :class="[
                  {
                    'is-invalid':
                      v$.form.period_id.$error ||
                      reqResponse.errors[
                        'appointments.' + formIndex + '.period_id'
                      ] ||
                      reqResponse.errors['period_id']
                  },
                  'ps__child--consume'
                ]"
                class="cs-select mb-3"
                :options="periods"
                placeholder="Period"
                data-test-id="create-app-pass-form-period"
                :clearable="false"
                label="label"
              >
                <template #selected-option="{ label }">
                  {{
                    label && label.length > 20
                      ? label.slice(0, 12) + ".."
                      : label
                  }}
                </template>
                <template #footer>
                  <div
                    v-if="v$.form.$error && v$.form.period_id.required.$invalid"
                    class="invalid-feedback"
                  >
                    This field is required
                  </div>
                  <div
                    v-if="
                      reqResponse.errors[
                        'appointments.' + formIndex + '.period_id'
                      ]
                    "
                    class="invalid-feedback"
                  >
                    {{
                      reqResponse.errors[
                        "appointments." + formIndex + ".period_id"
                      ][0]
                    }}
                  </div>
                  <div
                    v-if="reqResponse.errors['period_id']"
                    class="invalid-feedback"
                  >
                    {{ reqResponse.errors["period_id"][0] }}
                  </div>
                </template>
              </VSelect>
            </CCol>
            <CCol sm="4" class="mb-3 ps-auto mb-md-0 ps-sm-0 z-index-10">
              <CustomTimePicker
                format="HH:mm"
                mode="time"
                v-model="form.time"
                placeholder="Time(blank=now)"
                :class="{
                  'is-invalid': !!(
                    v$.form.time.$error ||
                    reqResponse.errors[
                      'appointments.' + formIndex + '.for_time'
                    ] ||
                    reqResponse.errors['for_time']
                  ),
                  'ms-3': editablePass && !editablePass.period
                }"
                @update:model-value="checkForExistApp()"
                @input="checkForExistApp()"
              >
                <template #footer>
                  <div
                    v-if="
                      reqResponse.errors[
                        'appointments.' + formIndex + '.for_time'
                      ]
                    "
                    class="invalid-feedback"
                  >
                    {{
                      reqResponse.errors[
                        "appointments." + formIndex + ".for_time"
                      ][0]
                    }}
                  </div>
                  <div
                    v-if="reqResponse.errors['for_time']"
                    class="invalid-feedback"
                  >
                    {{ reqResponse.errors["for_time"][0] }}
                  </div>
                </template>
              </CustomTimePicker>
            </CCol>
            <div id="pass-form-warning" class="px-3">
              <div
                v-if="appointment_exists_within_15_mins"
                class="text-red mb-1"
              >
                Warning: This student has an appointment within 15 mins of the
                time you chose.
              </div>
            </div>
          </CRow>

          <CRow>
            <CCol sm="12">
              <LazyLoadSelect
                :classes="[
                  {
                    'is-invalid':
                      v$.form.from_id.$error ||
                      reqResponse.errors[
                        'appointments.' + formIndex + '.from_id'
                      ] ||
                      reqResponse.errors['from_id']
                  },
                  'mb-3'
                ]"
                :is-multiple="false"
                :selected="form.from_id"
                type="combined"
                placeholder="From:"
                :default="defaultFrom"
                :prepend-items="[
                  defaultFrom,

                  { label: 'From: Me', value: meValue }
                ]"
                :exclude-options="[{ label: 'From: Me', value: meValue }]"
                @changed="
                  (value) => {
                    form.from_id = value
                  }
                "
              >
                <template #footer>
                  <div
                    v-if="v$.form.$error && v$.form.from_id.required.$invalid"
                    class="invalid-feedback"
                  >
                    This field is required
                  </div>
                  <div
                    v-if="
                      reqResponse.errors[
                        'appointments.' + formIndex + '.from_id'
                      ]
                    "
                    class="invalid-feedback"
                  >
                    {{
                      reqResponse.errors[
                        "appointments." + formIndex + ".from_id"
                      ][0]
                    }}
                  </div>
                  <div
                    v-if="reqResponse.errors['from_id']"
                    class="invalid-feedback"
                  >
                    {{ reqResponse.errors["from_id"][0] }}
                  </div>
                </template>
              </LazyLoadSelect>
            </CCol>
          </CRow>

          <CRow>
            <CCol sm="12">
              <CRow>
                <CCol sm="12">
                  <LazyLoadSelect
                    :classes="[
                      {
                        'is-invalid':
                          v$.form.to_id.$error ||
                          reqResponse.errors[
                            'appointments.' + formIndex + '.to_id'
                          ] ||
                          reqResponse.errors['to_id']
                      },
                      'mb-3'
                    ]"
                    :is-multiple="false"
                    :selected="form.to_id"
                    type="combined"
                    placeholder="To:"
                    :default="defaultTo"
                    :prepend-items="[defaultTo]"
                    :exclude-options="[{ value: meValue }]"
                    @changed="
                      (value) => {
                        form.to_id = value
                      }
                    "
                  >
                    <template #footer>
                      <div
                        v-if="v$.form.$error && v$.form.to_id.required.$invalid"
                        class="invalid-feedback"
                      >
                        This field is required
                      </div>
                      <div
                        v-if="
                          reqResponse.errors[
                            'appointments.' + formIndex + '.to_id'
                          ]
                        "
                        class="invalid-feedback"
                      >
                        {{
                          reqResponse.errors[
                            "appointments." + formIndex + ".to_id"
                          ][0]
                        }}
                      </div>
                      <div
                        v-if="reqResponse.errors['to_id']"
                        class="invalid-feedback"
                      >
                        {{ reqResponse.errors["to_id"][0] }}
                      </div>
                    </template>
                  </LazyLoadSelect>
                </CCol>
              </CRow>
            </CCol>
          </CRow>

          <CRow>
            <CCol sm="6" class="pe-auto pe-sm-2">
              <VSelect
                v-model="form.recurrence_type"
                class="cs-select mb-3 ps__child--consume"
                :options="repeatOptions"
                :clearable="false"
                label="label"
                :reduce="(item) => item.value"
                :disabled="!!editablePass"
                @update:model-value="resetRepaetOptions"
              />
            </CCol>
            <CCol
              v-if="form.recurrence_type === 'weekly'"
              sm="6"
              class="ps-auto mb-4 ps-sm-2 mb-sm-0"
            >
              <div
                class="weekdays-options-container pt-2 d-flex justify-content-between"
                :class="{ 'is-invalid': v$.form.recurrence_days.$error }"
              >
                <div v-for="(day, index) in weekDays" :key="index">
                  <div
                    class="weekday-box"
                    :class="{
                      active: form.recurrence_days.find(
                        (el) => el === day.value
                      )
                    }"
                    @click="setWeekDay(day.value)"
                  >
                    {{ day.label }}
                  </div>
                </div>
              </div>
              <div
                v-if="
                  v$.form.$error && v$.form.recurrence_days.required.$invalid
                "
                class="invalid-feedback mb-3"
              >
                This field is required
              </div>
            </CCol>
          </CRow>

          <CRow>
            <CCol sm="12">
              <DatePicker
                v-if="!!form.recurrence_type"
                v-model="v$.form.recurrence_end_at.$model"
                :masks="{ L: 'MM/DD/YYYY' }"
                class="cs-date-picker"
                placeholder="Date"
                :popover="{ visibility: 'click' }"
                :min-date="today"
                :disabled-dates="
                  form.recurrence_type === 'daily'
                    ? { weekdays: [1, 7] }
                    : false
                "
                :max-date="maxDate"
              >
                <template #default="{ inputValue, togglePopover }">
                  <div class="input-icon-field" @click="togglePopover">
                    <div class="form-group">
                      <input
                        readonly
                        class="form-control"
                        :class="{
                          'is-invalid':
                            (v$.form.$error &&
                              v$.form.recurrence_end_at.$error) ||
                            reqResponse.errors[
                              'appointments.' + formIndex + '.recurrence_end_at'
                            ] ||
                            reqResponse.errors['recurrence_end_at']
                        }"
                        placeholder="Date"
                        :value="'End on ' + inputValue"
                      />
                      <div
                        v-if="
                          v$.form.$error &&
                          v$.form.recurrence_end_at.required.$invalid
                        "
                        class="invalid-feedback"
                      >
                        This field is required
                      </div>
                      <div
                        v-if="
                          v$.form.$error &&
                          v$.form.recurrence_end_at.required &&
                          v$.form.recurrence_end_at.isAfterStartTime.$invalid
                        "
                        class="invalid-feedback"
                      >
                        Recurrence end date must be greater than start date
                      </div>
                      <div
                        v-if="
                          reqResponse.errors[
                            'appointments.' + formIndex + '.recurrence_end_at'
                          ]
                        "
                        class="invalid-feedback"
                      >
                        {{
                          reqResponse.errors[
                            "appointments." + formIndex + ".recurrence_end_at"
                          ][0]
                        }}
                      </div>
                      <div
                        v-if="reqResponse.errors['recurrence_end_at']"
                        class="invalid-feedback"
                      >
                        {{ reqResponse.errors["recurrence_end_at"][0] }}
                      </div>
                    </div>
                    <span class="field-icon">
                      <i class="fi flaticon-calendar me-1" />
                    </span>
                  </div>
                </template>
              </DatePicker>
            </CCol>
          </CRow>

          <div class="form-group">
            <textarea
              maxlength="250"
              v-model="form.reason"
              class="form-control"
              :class="{
                'is-invalid':
                  v$.form.reason.$error ||
                  reqResponse.errors['appointments.' + formIndex + '.reason'] ||
                  reqResponse.errors['reason']
              }"
              placeholder="Reason..."
              data-test-id="create-app-pass-form-reason"
              rows="1"
            />
            <div class="text-end mt-1">250 characters max</div>
            <div
              v-if="v$.form.$error && v$.form.reason.required.$invalid"
              class="invalid-feedback"
            >
              This field is required
            </div>
            <div
              v-if="reqResponse.errors['appointments.' + formIndex + '.reason']"
              class="invalid-feedback"
            >
              {{
                reqResponse.errors["appointments." + formIndex + ".reason"][0]
              }}
            </div>
            <div v-if="reqResponse.errors['reason']" class="invalid-feedback">
              {{ reqResponse.errors["reason"][0] }}
            </div>
          </div>

          <div
            v-if="!isMultiple"
            class="form-group form-actions mt-4 text-center"
          >
            <div class="d-flex align-items-center justify-content-center">
              <div v-if="editablePass && editablePass.id" class="d-flex">
                <div @click="submit(false)">
                  <CLoadingButton
                    :spinner="isLoading"
                    :disabled="isLoading"
                    :loading="isLoading"
                    class="me-2 px-4 py-3 px-sm-5 py-sm-3 btn bg-gradient-blue text-white"
                  >
                    Update
                  </CLoadingButton>
                </div>
                <div class="ms-2" @click="cancelEdit()">
                  <CButton class="me-2 btn-gradient-border">
                    <span class="btn px-4"
                      ><b class="text-graident-blue">Cancel</b></span
                    >
                  </CButton>
                </div>
              </div>
              <div v-else @click="submit(true)">
                <CLoadingButton
                  :spinner="isLoading"
                  :disabled="isLoading"
                  :loading="isLoading"
                  class="me-2 px-4 py-3 px-sm-5 py-sm-3 btn bg-gradient-blue text-white"
                  data-test-id="create-app-pass-form-submit-btn"
                >
                  Submit
                </CLoadingButton>
              </div>
              <router-link
                v-if="!isInView"
                class="text-graident-blue ms-3"
                to="/appointments"
              >
                <div class="d-flex align-items-center" @click="goToFullMenu()">
                  <img
                    width="18"
                    class="m-0 me-2"
                    src="@/assets/images/icons/gradient-link.png"
                  />
                  <span class="text-graident-blue">Go to full menu</span>
                </div>
              </router-link>
            </div>
          </div>
        </CForm>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import useVuelidate from "@vuelidate/core"
import { required, helpers, requiredIf } from "vuelidate/lib/validators"
import moment from "moment-timezone"
import LazyLoadSelect from "@/v3components/shared/Form/LazyLoadSelectOld.vue"
import { mapGetters } from "vuex"

export default {
  name: "CreateAppoinmentsPassForm",
  emits: ["closeModal"],
  components: {
    LazyLoadSelect
  },
  props: ["isInView", "editablePass", "isMultiple", "formIndex"],
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      isLoading: false,
      form: {
        user_id: null,
        for_date: null,
        period_id: null,
        time: "",
        from_id: null,
        to_id: null,
        recurrence_type: null,
        recurrence_days: [],
        recurrence_end_at: null,
        reason: ""
      },
      appointment_exists_within_15_mins: false,
      timeoutForExistApp: null,
      repeatOptions: [
        {
          label: "No Repetition",
          value: null
        },
        {
          label: "Daily (M-F)",
          value: "daily"
        },
        {
          label: "Weekly",
          value: "weekly"
        },
        {
          label: "Monthly",
          value: "monthly"
        }
      ],
      weekDays: [
        {
          label: "Mo",
          value: "Monday"
        },
        {
          label: "Tu",
          value: "Tuesday"
        },
        {
          label: "We",
          value: "Wednesday"
        },
        {
          label: "Th",
          value: "Thursday"
        },
        {
          label: "Fr",
          value: "Friday"
        },
        {
          label: "Sa",
          value: "Saturday"
        },
        {
          label: "Su",
          value: "Sunday"
        }
      ],
      reqResponse: {
        type: "",
        message: "",
        errors: {}
      }
    }
  },
  validations() {
    return {
      form: {
        user_id: {
          required
        },
        for_date: {
          required
        },
        period_id: !this.editablePass ? { required } : {},
        time: {},
        from_id: {
          required
        },
        to_id: {
          required
        },
        recurrence_type: {},
        recurrence_end_at:
          this.form && this.form.recurrence_type !== null
            ? {
                required,
                isAfterStartTime: (value, model) => {
                  const min = moment(model.for_date).format("MM/DD/YYYY")
                  const date = moment(value).format("MM/DD/YYYY")
                  if (!helpers.req(value) >= min || moment(date).isAfter(min)) {
                    return true
                  } else {
                    return false
                  }
                }
              }
            : {},
        recurrence_days: {
          required: requiredIf((model) => {
            return model.recurrence_type && model.recurrence_type === "weekly"
          })
        },
        reason: !this.editablePass ? { required } : {},
        child_id: {}
      }
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "authentication/user",
      activeTab: "adultAptPass/activeTab",
      school: "schools/activeSchool",
      periods: "periods/formListStudents"
    }),
    isValid() {
      return !this.v$.form.$invalid
    },
    meValue() {
      return {
        id: this.currentUser.id,
        type: "App\\Models\\User",
        passesToday: this.currentUser.passes_for_today_count
      }
    },
    defaultTo() {
      return {
        value: this.meValue,
        label: "To: Me"
      }
    },
    defaultFrom() {
      return {
        value: {
          id: 0,
          type: "App\\Models\\User",
          passesToday: null
        },
        label: "From: System"
      }
    },
    today() {
      return moment().format("MM/DD/YYYY HH:mm")
    },
    maxDate() {
      return this.$helpers.maxSchoolYearDate()
    },
    minDate() {
      return this.editablePassDate && this.editablePassDate < this.today
        ? this.editablePassDate
        : this.today
    },
    editablePassDate() {
      if (
        this.editablePass &&
        this.activeTab === "nextSevenDays" &&
        this.editablePass.recurrence_appointment_pass
      ) {
        return moment(
          this.editablePass.recurrence_appointment_pass.for_date
        ).format("MM/DD/YYYY HH:mm")
      }
      return this.editablePass
        ? moment(this.editablePass.for_date).format("MM/DD/YYYY HH:mm")
        : null
    },
    isForDateEditable() {
      if (
        this.activeTab === "nextSevenDays" &&
        this.editablePass &&
        this.editablePass.recurrence_appointment_pass
      ) {
        return (
          this.editablePassDate &&
          moment(this.editablePass.recurrence_appointment_pass.for_date).format(
            "MM-DD-YYYY"
          ) >= moment().format("MM-DD-YYYY")
        )
      }
      return (
        this.editablePassDate &&
        moment(this.editablePass.for_date).format("MM-DD-YYYY") >=
          moment().format("MM-DD-YYYY")
      )
    },
    formatedForDate() {
      return moment(this.form.for_date).format("MM/DD/YYYY")
    }
  },
  watch: {
    editablePass: function (pass) {
      this.resetForm()
      this.resetResponseMessages()
      this.setFormByPassRecord(pass)
    },
    $route: function () {
      this.cancelEdit()
    }
  },
  created() {
    this.form.for_date = moment().format("MM/DD/YYYY HH:mm")
    this.formatMontlyWeekDay(this.form.for_date)
    this.form.recurrence_type = this.repeatOptions[0].value
  },
  unmounted() {
    this.cancelEdit()
  },
  methods: {
    submit(isCreate) {
      this.resetResponseMessages()
      if (this.isValid) {
        this.form.time = this.form.time
          ? moment(this.form.time, "hh:mm A").format("HH:mm")
          : moment().format("HH:mm")
        const appointments = [
          {
            from_id: this.form.from_id
              ? this.form.from_id.value.id
              : this.form.from_id,
            to_id: this.form.to_id ? this.form.to_id.value.id : this.form.to_id,
            from_type: this.form.from_id
              ? this.form.from_id.value.type
              : this.form.from_id,
            to_type: this.form.to_id
              ? this.form.to_id.value.type
              : this.form.to_id,
            for_date: this.$helpers.currTzDateTime(
              this.form.for_date,

              this.form.time
            ),
            for_time: this.form.time,
            period_id: this.form.period_id
              ? this.form.period_id.value
              : this.form.period_id,
            reason: this.form.reason,
            user_id: this.form.user_id
              ? this.form.user_id.value.id
              : this.form.user_id,
            recurrence_type: this.form.recurrence_type,
            recurrence_days: JSON.stringify({
              days: this.form.recurrence_days
            }),
            recurrence_end_at: this.form.recurrence_end_at
              ? this.$helpers.currTzDate(this.form.recurrence_end_at)
              : null,
            recurrence_week: this.form.recurrence_week,
            child_id: this.form.child_id
          }
        ]
        this.isLoading = true
        if (!isCreate) {
          this.editAppointmentPass(this.editablePass, appointments[0])
        } else {
          this.createAppointmentPasses(appointments)
        }
      } else {
        this.v$.$touch()
      }
    },
    createAppointmentPasses(appointments) {
      this.$store
        .dispatch("adultAptPass/createAppointmentPass", {
          appointments
        })
        .then(() => {
          this.resetForm()
          this.setSuccessResponse("Successfully created.")
          this.isLoading = false
          setTimeout(() => {
            this.resetResponseMessages()
            this.$emit("closeModal")
            this.$router.push("/appointments")
          }, 1000)
        })
        .catch((err) => {
          const response = err.response.data
          this.setErrorResponse(response.message, response.errors)
          this.isLoading = false
        })
    },
    editAppointmentPass(pass, data) {
      const id = pass.recurrence_appointment_pass
        ? pass.recurrence_appointment_pass.recurrence_appointment_pass_id
        : pass.id
      const action = pass.recurrence_appointment_pass
        ? "adultAptPass/editRecurrenceAppPass"
        : "adultAptPass/editAppointmentPass"
      this.$store
        .dispatch(action, {
          id,
          data
        })
        .then(() => {
          this.setSuccessResponse("Successfully updated!")
          this.isLoading = false
          setTimeout(() => {
            this.$emit("closeModal")
            this.cancelEdit()
          }, 1200)
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            const response = err.response.data
            this.setErrorResponse(response.message, response.errors)
          }
          this.isLoading = false
        })
    },
    cancelEdit() {
      this.$emit("closeModal")
      this.$store.commit("adultAptPass/SET_EDITABLE_PASS", null)
      this.resetForm()
      this.resetResponseMessages()
      this.formatMontlyWeekDay(this.form.for_date)
    },
    setWeekDay(day) {
      if (this.form.recurrence_days && this.form.recurrence_days.length) {
        if (this.form.recurrence_days.filter((el) => el === day).length) {
          this.form.recurrence_days = this.form.recurrence_days.filter(
            (el) => el !== day
          )
        } else {
          this.form.recurrence_days.push(day)
        }
      } else {
        this.form.recurrence_days.push(day)
      }
    },

    formatMontlyWeekDay(date) {
      if (date) {
        const dayOfMonth = moment.utc(date).format("DD")
        const dayOfweek = moment.utc(date).format("dddd")
        const numberofweek = Math.floor((dayOfMonth - 1) / 7) + 1
        let prefix = ""
        switch (numberofweek) {
          case 1:
            prefix = "first"
            break
          case 2:
            prefix = "second"
            break
          case 3:
            prefix = "third"
            break
          case 4:
            prefix = "fourth"
            break
          default:
            prefix = "last"
        }
        this.form.recurrence_week =
          '{"' + numberofweek + '":"' + dayOfweek + '"}'
        this.repeatOptions[3].label =
          "Monthly on the " + prefix + " " + dayOfweek
      }
    },
    resetRepaetOptions() {
      this.v$.$reset()
      this.form.recurrence_days = []
      this.form.recurrence_end_at = null
      this.form.recurrence_week = null
      this.formatMontlyWeekDay(this.form.for_date)
    },
    setFormByPassRecord(pass) {
      if (pass) {
        let isDefaultFrom
        if (pass.from == null) {
          isDefaultFrom = true
        } else {
          isDefaultFrom =
            this.defaultFrom && this.defaultFrom.value.id === pass.from.id
        }
        this.form.from_id = isDefaultFrom
          ? this.defaultFrom
          : {
              label: pass.from.name,
              value: {
                id: pass.from.id,
                type: pass.from_type
              }
            }

        const isDefaultTo =
          this.defaultTo && this.defaultTo.value.id === pass.to.id
        this.form.to_id = isDefaultTo
          ? this.defaultTo
          : {
              label: pass.to.name,
              value: {
                id: pass.to.id,
                type: pass.to_type
              }
            }

        this.form.for_date = pass.for_date
          ? moment(pass.for_date).format("MM/DD/YYYY HH:mm")
          : null
        this.form.time = pass.for_date
          ? this.$helpers.transformDate(pass.for_date, "HH:mm")
          : ""
        if (pass.latest_comment) {
          this.form.reason = pass.latest_comment.comment
        }
        this.form.user_id = {
          label: pass.user.first_name + " " + pass.user.last_name,
          value: {
            id: pass.user.id,
            type: "App\\Models\\User"
          }
        }
        this.form.period_id = pass.period
          ? {
              label: pass.period.name,
              value: pass.period.id
            }
          : null
        if (pass.recurrence_appointment_pass) {
          if (this.activeTab === "nextSevenDays") {
            this.form.for_date = pass.recurrence_appointment_pass.for_date
              ? moment(pass.recurrence_appointment_pass.for_date).format(
                  "MM/DD/YYYY HH:mm"
                )
              : ""
            this.form.child_id = pass.id
          }
          this.form.recurrence_type =
            pass.recurrence_appointment_pass.recurrence_type
          this.form.recurrence_days = pass.recurrence_appointment_pass
            .recurrence_days
            ? JSON.parse(pass.recurrence_appointment_pass.recurrence_days).days
            : null
          this.form.recurrence_end_at = pass.recurrence_appointment_pass
            .recurrence_end_at
            ? moment(pass.recurrence_appointment_pass.recurrence_end_at).format(
                "MMM DD YYYY"
              )
            : null
          this.form.recurrence_week =
            pass.recurrence_appointment_pass.recurrence_week
          this.form.time
        }
        this.formatMontlyWeekDay(this.form.for_date)
      }
    },
    resetForm() {
      this.v$.$reset()
      this.form = {
        user_id: null,
        for_date: moment().format("MM/DD/YYYY HH:mm"),
        period_id: null,
        time: "",
        from_id: this.defaultFrom,
        to_id: this.defaultTo,
        recurrence_type: this.repeatOptions[0].value,
        recurrence_days: [],
        recurrence_end_at: null,
        reason: ""
      }
      this.formatMontlyWeekDay(this.form.for_date)
    },
    resetResponseMessages() {
      this.reqResponse = {
        type: "",
        message: "",
        errors: {}
      }
    },
    setSuccessResponse(message) {
      this.reqResponse = {
        type: "success",
        message: message ? message : "Success!",
        errors: {}
      }
    },
    setErrorResponse(message, errors) {
      this.reqResponse = {
        type: "danger",
        message: message ? message : "Something went wrong!",
        errors: errors ? errors : {}
      }
    },
    setFormData(form) {
      if (form) {
        for (const key in form) {
          if (Object.hasOwnProperty.call(form, key)) {
            const value = form[key]
            if (value && key !== "user_id") {
              this.form[key] = value
            }
          }
        }
      }
    },
    refreshForm() {
      this.resetForm()
      this.resetResponseMessages()
    },
    goToFullMenu() {
      this.$emit("closeModal")
    },
    checkForExistApp() {
      clearTimeout(this.timeoutForExistApp)
      this.timeoutForExistApp = setTimeout(() => {
        if (
          this.form.user_id &&
          this.form.for_date &&
          this.form.time &&
          (!this.editablePass ||
            (this.editablePass &&
              !this.editablePass.recurrence_appointment_pass))
        ) {
          const params = {
            studentID: this.form.user_id.value.id,
            apt_for_time: this.$helpers.currTzDateTime(
              this.form.for_date,
              this.form.time
            )
          }
          if (this.editablePass) {
            params.apt_id = this.editablePass.id
          }
          this.$store
            .dispatch("adultAptPass/getInfoForExistApp", params)
            .then((response) => {
              if (response.data) {
                this.appointment_exists_within_15_mins =
                  response.data.appointment_exists_within_15_mins
              }
            })
            .catch(() => {
              this.appointment_exists_within_15_mins = false
            })
        } else {
          this.appointment_exists_within_15_mins = false
        }
      }, 300)
    },
    showForDateCalendar(toggler) {
      if (this.isForDateEditable || !this.editablePassDate) {
        toggler()
      }
    }
  }
}
</script>

<style></style>
